import $i18n from '@alife/panda-i18n';
import React, { useCallback, useEffect, useMemo, useRef, useState, } from 'react';
import { CnButton, CnMessage, CnDialog, CnBalloon } from '@alife/cn-ui';
import { wmsCnRequestV3 } from '@alife/cn-biz-utils-sc';
import * as _ from 'lodash';
import classnames from 'classnames';
// import zhCN from './locale/zh-CN';
import { componentName } from './const';
import CnCommonPrint, { doLog, } from '@alife/cn-sc-print-base/es/base';
import { callWindVane, isAndriodEnvirnment } from './init';
import SelectPrintDialog from './components/select-printer';
import { deliveryTasksCallback, formatData, getSelectedRecords, handleSelectedKeys, transformBatchTaskInfo, transformTaskInfo, getProdHostName, } from './utils';
import './index.scss';
let cnPrintInstance;
let mixPrintInstance;
let key2Request;
const defaultButtonConfig = {};
function getUUID() {
    const len = typeof window.len === 'undefined' ? 8 : window.len;
    let radix = typeof window.radix === 'undefined' ? 16 : window.radix;
    const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
    const uuid = [];
    let i;
    radix = radix || chars.length;
    if (len) {
        for (i = 0; i < len; i++)
            uuid[i] = chars[0 | (Math.random() * radix)];
    }
    else {
        let r;
        uuid[8] = '-';
        uuid[13] = '-';
        uuid[18] = '-';
        uuid[23] = '-';
        uuid[14] = '4';
        for (i = 0; i < 36; i++) {
            if (!uuid[i]) {
                r = 0 | (Math.random() * 16);
                uuid[i] = chars[i == 19 ? (r & 0x3) | 0x8 : r];
            }
        }
    }
    return uuid.join('');
}
function WmsPrint({ 
// locale = zhCN,
children, queryParams: propsQueryParams, queryParamCheck, selectedRowKeys: propsSelectedRowKeys, selectedRowRecords: propsSelectedRecord, config: oldConfig, configJsx, csrfUrl = '/api/csrf', onSuccess, btnProps = {}, requestBeforePrint, requestBeforePrint4L2, isMenuItem = false, _bindFilterData, tableRef, progressParams: propsProgressParams, disabled: buttonDisabled = false, buttonConfig = defaultButtonConfig, 
/** effect 监听_state 会导致死循环 */
_state = {}, formatResponse, }) {
    const config = useMemo(() => {
        return configJsx?.() || oldConfig || {};
    }, [configJsx, oldConfig]);
    const { isFetchConfig = true, isMixPrint = true, isReloadTable = true, progressParams: configProgressParams, enableRule, getIsEnable, } = config;
    const { className } = btnProps;
    const queryParams = useMemo(() => {
        return propsQueryParams || _bindFilterData || {};
    }, [propsQueryParams, _bindFilterData]);
    const progressParams = useMemo(() => {
        return propsProgressParams || configProgressParams || {};
    }, [propsProgressParams, configProgressParams]);
    const csrfIdRetryCount = useRef(0);
    const [selectedRecords, setSelectedRecords] = useState([]);
    const selectedRecordsRef = useRef(null);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [loading, setLoading] = useState(false);
    const selectedRowKeysRef = useRef(null);
    const [csrfId, setCsrfId] = useState('');
    const [disabled, setDisabled] = useState(false);
    const [name, setName] = useState('');
    const currentPrintRef = useRef();
    const [needConfig, setNeedConfig] = useState(false);
    const needConfigRef = useRef(null);
    const [selectPrintVisible, setSelectPrintVisible] = useState(false);
    const [hoverInfo, setHoverInfo] = useState({});
    const [printersList, setPrintersList] = useState([]);
    const printerDataSourceRef = useRef(null);
    const csrfRef = useRef(null);
    useEffect(() => {
        csrfRef.current = csrfId;
    }, [csrfId]);
    useEffect(() => {
        setSelectedRecords(propsSelectedRecord);
    }, [propsSelectedRecord]);
    useEffect(() => {
        selectedRecordsRef.current = selectedRecords;
        if (!selectedRecordsRef.current?.length) {
            setDisabled(true);
        }
        else if (getIsEnable) {
            setDisabled(getIsEnable(selectedRecordsRef.current));
        }
        else if (typeof enableRule !== 'undefined') {
            setDisabled(!!enableRule);
        }
        else if (typeof buttonDisabled !== 'undefined') {
            setDisabled(buttonDisabled);
        }
    }, [selectedRecords, getIsEnable, enableRule, buttonDisabled]);
    useEffect(() => {
        const tempHoverInfo = { ...hoverInfo };
        const tempContent = buttonConfig?.buttonHoverInfo?.hoverInfo?.content || '';
        const tempTitle = buttonConfig?.buttonHoverInfo?.hoverInfo?.title || '';
        if (tempContent) {
            if (typeof tempContent === 'function') {
                tempHoverInfo.content = tempContent(null, _state);
            }
            else if (tempContent.group === '__dataSource__') {
                tempHoverInfo.content =
                    _state[tempContent.param][tempContent.secondParam];
            }
            else {
                tempHoverInfo.content = tempContent;
            }
        }
        if (tempTitle) {
            if (typeof tempTitle === 'function') {
                tempHoverInfo.title = tempTitle(null, _state);
            }
            else if (tempTitle.group === '__dataSource__') {
                tempHoverInfo.title = _state[tempTitle.param][tempTitle.secondParam];
            }
            else {
                tempHoverInfo.title = tempTitle;
            }
        }
        setHoverInfo(tempHoverInfo);
    }, [buttonConfig]);
    useEffect(() => {
        setSelectedRowKeys(propsSelectedRowKeys);
    }, [propsSelectedRowKeys]);
    useEffect(() => {
        selectedRowKeysRef.current = selectedRowKeys;
    }, [selectedRowKeys]);
    useEffect(() => {
        printerDataSourceRef.current = printersList;
    }, [printersList]);
    useEffect(() => {
        needConfigRef.current = needConfig;
    }, [needConfig]);
    const printInstance = useMemo(() => {
        if (!cnPrintInstance) {
            cnPrintInstance = new CnCommonPrint();
            cnPrintInstance.config = {};
        }
        if (isMixPrint) {
            if (mixPrintInstance) {
                return mixPrintInstance;
            }
            mixPrintInstance = new CnCommonPrint({
                isNeedWetPrint: true,
                isNeedCloudPrint: false,
                isNeedPddPrint: true,
                isNeedJdPrint: true,
                isNeedBytePrint: true,
                isNeedKuaiShouPrint: true,
                isNeedVipPrint: true,
                isNeedHuaWeiPrint: true,
                isNeedXhsPrint: true, // 是否开启小红书打印
            });
            mixPrintInstance.config = {};
            return mixPrintInstance;
        }
        return cnPrintInstance;
    }, [isMixPrint]);
    const fetchConfig = useCallback(() => {
        if (!key2Request) {
            key2Request = fetch(`${getProdHostName()}/api/print/wmiconfig/PrinterSelectAndEncoding/key2`, {
                method: 'post',
                mode: 'cors',
                credentials: 'include',
            }).then((res) => res.json());
        }
        key2Request
            .then((data) => {
            setNeedConfig(data?.data);
        })
            .catch((e) => console.error(e));
    }, []);
    const initMessageCenter = useCallback(() => {
        printInstance?.addEventListen?.('getPrintConfigNameList', (result) => {
            const { data: { configList = [] }, } = result;
            const tmpPrintersList = [];
            configList.forEach((cell) => {
                tmpPrintersList.push({
                    label: cell.configName,
                    value: cell.configPrinterName,
                });
            });
            setPrintersList(tmpPrintersList);
        });
    }, [printInstance]);
    const initWetPrint = useCallback(() => {
        if (isAndriodEnvirnment)
            return;
        if (printInstance) {
            printInstance.handlerError = function () {
                CnMessage.warning($i18n.get({
                    id: 'ThePrintControlIsAbnormalPlease_1471847901',
                    dm: '打印控件异常，请确认后刷新页面重试！',
                }));
            };
            printInstance.handlerClose = function () {
                CnMessage.warning($i18n.get({
                    id: 'ThePrintLinkIsBrokenPlease_35510703',
                    dm: '打印链接断开，请确认后刷新页面重试！',
                }));
            };
        }
        // 请求打印配置
        initMessageCenter();
        isFetchConfig && fetchConfig();
    }, [fetchConfig, initMessageCenter, printInstance, isFetchConfig]);
    const fetchCsrf = useCallback(() => {
        // 3次尝试获取csrfId
        if (csrfIdRetryCount.current > 2) {
            return;
        }
        csrfIdRetryCount.current += 1;
        let origin = '';
        try {
            let tmpUrl = '';
            if (csrfUrl.indexOf('https:') === 0) {
                tmpUrl = csrfUrl;
            }
            else if (csrfUrl.indexOf('http:') === 0) {
                tmpUrl = csrfUrl.replace('http:', 'https:');
            }
            else if (csrfUrl.indexOf('//') === 0) {
                tmpUrl = `https:${csrfUrl}`;
            }
            else if (csrfUrl.indexOf('/') === 0) {
                tmpUrl = `${location.origin}${csrfUrl}`;
            }
            else {
                tmpUrl = csrfUrl;
            }
            const tmp = new URL(tmpUrl);
            origin = tmp.origin;
            return wmsCnRequestV3?.getCsrfId?.((token) => {
                if (token) {
                    return setCsrfId(token);
                }
                fetchCsrf();
            }, origin);
        }
        catch (e) {
            console.error(e);
        }
    }, [csrfUrl]);
    useEffect(() => {
        const { socketType = 'websocket', name: configName } = config || {};
        let printSocket = localStorage.getItem('printSocket');
        if (!printSocket) {
            localStorage.setItem('printSocket', socketType);
            printSocket = socketType;
        }
        if (window.iBirdHybirdApi) {
            window.iBirdHybirdApi.setMessageCallback('CallbackFunc', (__unuse, args) => {
                const res = JSON.parse(args[1]);
                const { module = '', toDo = '' } = res;
                if (module.toLowerCase() === 'print') {
                    if (toDo === 'sendTask') {
                        deliveryTasksCallback(res, config);
                    }
                }
            });
            if (printSocket !== 'hybridapi') {
                initWetPrint();
            }
        }
        else {
            initWetPrint();
            localStorage.setItem('selectedRowKeysStorage', '');
        }
        setName(configName || '');
        fetchCsrf();
    }, [printInstance, config, initWetPrint, fetchCsrf]);
    function handleError(res, task = {}) {
        const { extendTaskRemark = '[]' } = task;
        let parsedExtendTaskRemark;
        const remark = [];
        try {
            parsedExtendTaskRemark = JSON.parse(extendTaskRemark);
            parsedExtendTaskRemark.forEach((item) => {
                const { label = '', value = '' } = item;
                if (item.forEach) {
                    item.forEach((remarkItem) => {
                        const { label: tmpLabel, value: tmpValue } = remarkItem;
                        remark.push(`${tmpLabel}:${tmpValue}`);
                    });
                }
                else if (label && value) {
                    remark.push(`${label}:${value}`);
                }
            });
        }
        catch (e) {
            console.error(e);
        }
        let errorMsg = '';
        if (res.error && res.error.message) {
            errorMsg =
                res.error.message + (remark.length ? `[${remark.join(';')}]` : '');
        }
        else {
            const errorCode = typeof res.status !== 'undefined' ? res.status : '';
            const remarkDesc = remark.length ? `[${remark.join(';')}]` : '';
            errorMsg = $i18n.get({
                id: 'PrintRequestFailedPleaseCheckBefore_1804043096',
                dm: '打印请求失败，请核对后再打印或补打。[httpCode:{errorCode}]{remarkDesc}',
            }, { errorCode, remarkDesc });
        }
        if (errorMsg) {
            CnDialog.alert({
                title: errorMsg,
                size: 'small',
            });
        }
        // 接口调用异常埋点
        try {
            doLog?.({
                request: {
                    task: {
                        url: task.url || '',
                    },
                },
                status: 'failed',
                process: 'FRONT_END',
                action: 'recvPrintRequestResponse',
                pageUrl: window.location.href,
            });
        }
        catch (e) {
            console.error(e);
        }
    }
    function fetchAndPrint(taskList) {
        const task = taskList[0];
        if (!task) {
            return;
        }
        const params = JSON.parse(task.params);
        let tps = new Date().getTime();
        let tmpParams = {
            data: params,
            csrfId: csrfRef.current,
            _csrf: csrfRef.current,
        };
        if (typeof progressParams === 'function') {
            tmpParams = progressParams(tmpParams, selectedRecordsRef.current);
        }
        const options = {
            method: 'post',
            body: JSON.stringify(tmpParams),
            credentials: 'include',
            headers: {
                Accept: 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
            },
        };
        if (task.url.indexOf(window.location.hostname) === -1) {
            options.mode = 'cors';
        }
        fetch(task.url.replace('http:', ''), options)
            .then((res) => res.json())
            .then((res) => {
            let data = res?.data || {};
            const error = res?.error || res?.data?.error;
            if (error && error?.message) {
                handleError(res, task);
            }
            if (formatResponse) {
                data = formatResponse(data);
            }
            tps = new Date().getTime() - tps;
            if (data.standardData && data.standardData.length) {
                // 新版打印协议
                const { standardData = [] } = data || {};
                try {
                    doLog?.({
                        request: {
                            task: {
                                url: task.url,
                            },
                        },
                        status: 'success',
                        process: 'FRONT_END',
                        action: 'recvPrintRequestResponse',
                        pageUrl: window.location.href,
                        response: data,
                        timeCost: tps,
                        ...standardData[0],
                    });
                }
                catch (e) {
                    console.error(e);
                }
                standardData.forEach((item) => {
                    if (!item) {
                        return;
                    }
                    const { documents, ...rest } = item;
                    const { printer, configName } = rest;
                    const packagePrintData = {
                        printConfig: {
                            doPrintAlarm: false,
                            ...rest,
                        },
                        data: documents,
                    };
                    if (currentPrintRef?.current?.label) {
                        packagePrintData.printConfig.configName =
                            currentPrintRef?.current?.label;
                        packagePrintData.printConfig.printer =
                            currentPrintRef?.current?.value;
                    }
                    else if (!printer && configName) {
                        const currentItem = (printerDataSourceRef.current || []).find((cell) => {
                            return cell.label == configName;
                        });
                        packagePrintData.printConfig.printer = currentItem
                            ? currentItem.value
                            : '';
                    }
                    if (isAndriodEnvirnment) {
                        const sendData = printInstance?.getPrintAgreement(packagePrintData);
                        packagePrintData.printConfig.dataSource === 'CAINIAO' &&
                            callWindVane('bird-module', 'print', sendData, (result) => {
                                console.log('[fetchPrint success]', result);
                            }, (e) => {
                                console.log('[fetchPrint error]', e);
                            });
                    }
                    else {
                        printInstance?.printData?.(packagePrintData);
                    }
                });
            }
            else {
                // 老打印模型
                // documents可能没有，因为有些单据状态不对，还不能正常打印（零售通），所以有两种情况
                if (data.documents) {
                    // 如果存在多种单据，需要取出configName，分组打印
                    const printArr = formatData(data);
                    printArr.forEach((item) => {
                        const { pdfPrint, configName: itemCOnfigName, bizTyp, bizType, docType, documents, } = item;
                        let sendData = {
                            printConfig: {
                                protocolCustom: true,
                                pdfPrint: pdfPrint || false,
                                printType: data.printType || 'cloud',
                                warehouseId: data.warehouseId || '',
                                responseTime: data.responseTime || '',
                                networkTime: tps,
                                bizIdentity: data.bizIdentity || '',
                                taskID: data.taskID || '',
                                traceId: data.traceId || '',
                                configName: currentPrintRef?.current?.label || itemCOnfigName,
                                bizTyp: bizTyp || '',
                                bizType: bizType || '',
                                docType: docType || '',
                                printNode: data.printNode || '',
                                accountNo: data.accountNo || '',
                                accountName: data.accountName || '',
                                printer: currentPrintRef?.current?.value || data.printer || '',
                            },
                            data: documents,
                        };
                        // 调用打印接口
                        if (isAndriodEnvirnment) {
                            sendData = {
                                data: {
                                    requestID: getUUID(),
                                    version: '1.0',
                                    cmd: 'print',
                                    task: { taskID: item.taskID, documents: item.documents },
                                    warehouseId: data.warehouseId || params.warehouseId || '',
                                    responseTime: data.responseTime || '',
                                    networkTime: tps,
                                    bizIdentity: data.bizIdentity || '',
                                    taskID: data.taskID || '',
                                    traceId: data.traceId || '',
                                },
                                businessType: 1,
                                messageType: 3,
                                configName: itemCOnfigName,
                            };
                            callWindVane('bird-module', 'print', sendData, (result) => {
                                console.log('[fetchPrint success]', result);
                            }, (e) => {
                                console.log('[fetchPrint error]', e);
                            });
                        }
                        else {
                            printInstance?.printData?.(sendData);
                        }
                    });
                    // 正常打印单据量埋点
                }
                if (data.pddData) {
                    const protocol = location.protocol !== 'https'
                        ? 'ws://localhost:5000'
                        : 'wss://localhost:18653';
                    const socket = new WebSocket(protocol);
                    socket.onopen = () => {
                        socket.send(JSON.stringify({
                            cmd: 'setPrinterConfig',
                            requestID: '123458976',
                            version: '1.0',
                            printer: {
                                name: $i18n.get({
                                    id: 'PDDSinglePrinter',
                                    dm: 'PDD面单打印机',
                                }),
                                PrintTopLogo: true,
                                PrintBottomLogo: false,
                                horizontalOffset: 2,
                                verticalOffset: 2,
                            },
                        }));
                        socket.send(JSON.stringify(data.pddData));
                        socket.close();
                    };
                }
            }
            taskList.shift();
            execPrint(taskList);
        }, (res) => {
            handleError(res, task);
            taskList.shift();
            execPrint(taskList);
        });
    }
    function execPrint(taskList) {
        if (!taskList?.[0]) {
            setDisabled(false);
            onSuccess?.();
            if (isReloadTable === true) {
                tableRef?.remoteInstance?.load?.();
            }
            deliveryTasksCallback({
                success: true,
            }, config);
            return;
        }
        setDisabled(false);
        const taskNum = taskList?.length || 0;
        CnMessage.notice($i18n.get({
            id: 'PrintRequestIsBeingSentDo_385032042',
            dm: '正在发送打印请求,请勿关闭或刷新页面,剩余任务数：{taskNum}',
        }, { taskNum }));
        fetchAndPrint(taskList);
    }
    const handlePrintSelected = useCallback(() => {
        const tmpSelectedRowKeys = selectedRowKeysRef.current || [];
        const tmpSelectedRecords = selectedRecordsRef.current || [];
        if (_.isEmpty(tmpSelectedRowKeys)) {
            CnMessage.warning($i18n.get({
                id: 'PleaseSelectAtLeastOneRecord_858803615',
                dm: '请至少选择一项记录',
            }));
            return;
        }
        if (window.location.host.includes('gwms-pick.cainiao.com') ||
            window.location.host.includes('gwms-pick.sg.cainiao.com')) {
            // 拣选单补打，添加最多选择20条的条件限制
            if (config.billType === 'pick_bill' &&
                config.printType === 'repeat' &&
                tmpSelectedRowKeys?.length > 20) {
                CnMessage.warning($i18n.get({
                    id: 'OnlyAMaximumOfRecords_715097890',
                    dm: '最多只能选择20条记录',
                }));
                return;
            }
        }
        CnMessage.notice($i18n.get({
            id: 'PrintingTaskIsBeingIssuedPlease_318727833',
            dm: '正在下发打印任务，请稍后！',
        }));
        const { isBatch = false, printType, socketType = 'websocket' } = config;
        console.log('config', config);
        const userInfo = (window.headerGlobalData?.getData('userInfo') || {});
        const warehouseId = `${(window?.headerGlobalData?.getData('warehouseId') || '')}`;
        const userId = `${userInfo?.accountName || ''}`;
        // @XW 2019.03.19 新增去重逻辑
        let selectedRowKeysTemp = [...tmpSelectedRowKeys];
        let selectedRecordsTemp = [...tmpSelectedRecords];
        if (printType === 'normal') {
            selectedRowKeysTemp = handleSelectedKeys(tmpSelectedRowKeys, config);
            selectedRecordsTemp = getSelectedRecords(tmpSelectedRecords, selectedRowKeysTemp, config);
        }
        // @XW 2019.03.22 新增对去重后如果没有单据要打印了直接返回不去下发打印任务
        if (selectedRowKeysTemp.length === 0) {
            deliveryTasksCallback({ success: true }, config);
            return;
        }
        let arrTasks = [];
        if (isBatch) {
            console.log('printersList', printersList);
            const newConfig = {
                ...config,
                printersList,
            };
            const task = transformBatchTaskInfo(selectedRecordsTemp, selectedRowKeysTemp, warehouseId, userId, newConfig);
            arrTasks.push(task);
        }
        else {
            arrTasks = selectedRecordsTemp.map((item) => {
                return transformTaskInfo(item, warehouseId, userId, config);
            });
        }
        if (window.iBirdHybirdApi) {
            let printSocket = localStorage.getItem('printSocket');
            if (!printSocket) {
                localStorage.setItem('printSocket', socketType);
                printSocket = socketType;
            }
            if (printSocket === 'hybridapi') {
                window.iBirdHybirdApi.sendMessage('CallbackFunc', [
                    'print',
                    'sendTask',
                    JSON.stringify({ tasks: arrTasks }),
                ]);
            }
            else {
                execPrint(arrTasks);
                localStorage.setItem('selectedRowKeysStorage', '');
            }
        }
        else {
            // TODO
            // 在非智鸟容器下直接通过websocket通道进行打印处理
            execPrint(arrTasks);
            // 浏览器下面取消去重逻辑，否则过滤的单据处理失败无法再次勾选
            localStorage.setItem('selectedRowKeysStorage', '');
        }
    }, [config, printersList]);
    const handlePrint = useCallback(() => {
        const doPrint = () => {
            printInstance?.getPrintConfigNameList?.();
            if (needConfig) {
                setSelectPrintVisible(true);
            }
            else {
                handlePrintSelected();
            }
            setLoading(true);
            setTimeout(() => {
                setLoading(false);
            }, 2000);
        };
        const doQueryCheck = () => {
            if (queryParamCheck) {
                queryParamCheck(queryParams)
                    .then((msg) => {
                    if (msg) {
                        return CnMessage.warning(msg);
                    }
                    doPrint();
                })
                    .catch((e) => console.error(e));
            }
            else {
                doPrint();
            }
        };
        if (requestBeforePrint) {
            requestBeforePrint([setSelectedRecords, setSelectedRowKeys]).then(() => {
                doQueryCheck();
            });
        }
        else if (requestBeforePrint4L2) {
            const callback = requestBeforePrint4L2(selectedRecords);
            if (callback) {
                callback([setSelectedRecords, setSelectedRowKeys]).then(() => {
                    doQueryCheck();
                });
            }
            else {
                console.log('requestBeforePrint4L2====');
                doQueryCheck();
            }
        }
        else {
            doQueryCheck();
        }
    }, [
        requestBeforePrint,
        handlePrintSelected,
        queryParamCheck,
        needConfig,
        queryParams,
        printInstance,
    ]);
    const showTooltip = useMemo(() => {
        if (buttonConfig?.buttonHoverInfo?.needHover &&
            (hoverInfo.content || hoverInfo.title)) {
            return true;
        }
        return false;
    }, [
        buttonConfig?.buttonHoverInfo?.needHover,
        hoverInfo.content,
        hoverInfo.title,
    ]);
    const printButton = useMemo(() => {
        return (React.createElement(CnButton, { disabled: disabled, onClick: handlePrint, loading: loading, type: 'normal', "data-hottag-name": 'cn-sc-wms-biz-print', ...btnProps, className: classnames('cn-sc-print-button', className || '') }, children ||
            name ||
            $i18n.get({ id: '31255238681956352.CNTM', dm: '打印' })));
    }, [btnProps, children, className, disabled, handlePrint, loading, name]);
    const menuItem = useMemo(() => {
        return (React.createElement("div", { onClick: () => {
                if (!disabled) {
                    handlePrint();
                }
            }, "data-hottag-name": 'cn-sc-wms-biz-print', ...btnProps, className: classnames('cn-sc-print-button menu-mode', className || '', {
                disabled,
                loading,
            }), style: { flexGrow: 1 } }, name ||
            children ||
            $i18n.get({ id: '31255238681956352.CNTM', dm: '打印' })));
    }, [btnProps, children, className, disabled, handlePrint, loading, name]);
    return (React.createElement(React.Fragment, null,
        isMenuItem ? (React.createElement(React.Fragment, null, showTooltip ? (React.createElement(CnBalloon.Tooltip, { title: hoverInfo.title, trigger: menuItem }, hoverInfo.content)) : (menuItem))) : (React.createElement(React.Fragment, null, showTooltip ? (React.createElement(CnBalloon.Tooltip
        // closable={false}
        , { 
            // closable={false}
            title: hoverInfo.title, trigger: printButton }, hoverInfo.content)) : (printButton))),
        selectPrintVisible && (React.createElement(SelectPrintDialog, { visible: true, setVisible: setSelectPrintVisible, printerList: printersList, onOk: (printer) => {
                currentPrintRef.current = printer;
                handlePrintSelected();
            } }))));
}
WmsPrint.displayName = componentName;
export default WmsPrint;
