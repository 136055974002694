const domainMap = {
    data: {
        domestic: {
            daily: '//datahub.daily.taobao.net',
            pre: '//pre-data-wms.cainiao.com',
            pro: '//data-wms.cainiao.com',
        },
        sg: {
            pre: '//pre-wmsdatahub.sg.cainiao.com',
            pro: '//datahub.sg.cainiao.com',
        },
    },
};
export const domain = {
    domainMap,
    country: 'domestic',
    /**
     * 解析当前域名是什么环境
     * @param {*} domain
     */
    parseEnv(domainName) {
        // 环境, 默认为线上环境
        // HTTPS的线上环境也解析为HTTP环境
        let env = 'pro';
        // data-express 会命中 pre， 现调整为pre-
        if (domainName.indexOf('pre-') >= 0 ||
            domainName.indexOf('stg.') >= 0 ||
            domainName.indexOf('stg-') >= 0) {
            env = 'pre';
        }
        else if (domainName.indexOf('taobao.net') >= 0 ||
            domainName.indexOf('cainiao.test') >= 0) {
            // 解决某些日常的域名没有是taobao.net没有daily的问题 by xpp
            env = 'daily';
        }
        return env;
    },
    setCountry(country) {
        this.country = country;
    },
    /**
     * 设置domain
     * @param {*} moduleName 模块名称
     * @param {*} domainName
     */
    setDomain(moduleName, domainName) {
        this.domainMap[moduleName] = domainName;
    },
    getDomain(moduleName) {
        const env = this.parseEnv(window.location.host);
        return this.getHost(moduleName, env);
    },
    getHost(CODE, ENV) {
        const env = ENV || 'pro';
        if (this.domainMap[CODE]) {
            return this.domainMap[CODE][this.country][env] || '';
        }
        if (CODE === undefined) {
            return window.location.host;
        }
        return '';
    },
};
export const File_Name_Map = {
    package: '包裹管理',
    b2bHu: 'B2B容器管理',
    b2bPackage: 'B2B包裹管理',
    rvNotice: '入库通知单',
    pickBill: '拣选单管理',
    planOrderDi: '计划单管理',
};
export function getDefaultFileName(props) {
    // domain_cn 是自己的传入的domain的中文，就是文件名的前部分
    const { exportMode, domain: page, domain_cn, functionType } = props;
    let fileName = '';
    if (page || exportMode) {
        const time = window.dayjs?.()?.format('YYYYMMDD_HHmmss') || Date.now();
        fileName = `${File_Name_Map[page] || domain_cn || '离线导出'}_${time}`;
        if (functionType > 2) {
            fileName += '_离线';
        }
        if (exportMode === 2) {
            fileName += '_详情';
        }
    }
    return fileName;
}
export function getExportOfflineFilter(param, props) {
    const { exportMode, domain: page } = props;
    const filter = param.filter || {
        ...(param.queryParams || {}),
    };
    Object.keys(filter).forEach((key) => {
        if (!Array.isArray(filter[key])) {
            filter[key] = [filter[key]];
        }
    });
    const tmp = {
        ...param,
        filter,
    };
    if (exportMode) {
        tmp.exportMode = exportMode;
    }
    if (page) {
        tmp.domain = page;
    }
    return tmp;
}
