import $i18n from '@alife/panda-i18n'; // import cncAlert from '@alife/cn-sc-alert';
import { CnDialog } from '@alife/cn-ui';
export const isAndriodEnvirnment = /android/i.test(window.navigator.userAgent);
let isApiReady = isAndriodEnvirnment && !!window.WindVane;
if (isAndriodEnvirnment && !isApiReady) {
    const scriptNode = document.createElement('script');
    scriptNode.src = 'https://g.alicdn.com/mtb/lib-windvane/3.0.6/windvane.js';
    scriptNode.onload = () => {
        isApiReady = true;
    };
    document.body.appendChild(scriptNode);
}
export function callWindVane(className, methodName, params, success = () => { }, error = () => { }) {
    if (isApiReady) {
        // TODO 确认参数是否有问题
        window.WindVane.call(className, methodName, params, success, error);
    }
    else {
        // cncAlert({
        //   type: 'warning',
        //   message: '打印控件异常，请确认后刷新页面重试！',
        // });
        CnDialog.alert({
            title: $i18n.get({
                id: 'ThePrintControlIsAbnormalPlease_1471847901',
                dm: '打印控件异常，请确认后刷新页面重试！',
            }),
            // content: 'alert的快捷调用',
            size: 'small',
        });
    }
}
